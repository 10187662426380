.Typewriter__wrapper {
  font-size: 18px;
  font-weight: 500;
  font-Family: 'Roboto, Arial,  sans-serif',
}

.typewriter-container {
  font-size: 18px;
  font-weight: 600;
  font-Family: 'Roboto, Arial,  sans-serif';
  overflow: hidden;
}

.typewriter-text {
  display: inline-block;
  animation: typing 3s steps(20, end);
  white-space: nowrap;
  overflow: hidden;
}

.checkBox_main .mui-style-yz9k0d-MuiInputBase-input {
  width: 25px;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}