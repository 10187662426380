html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, Comfortaa, Play, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
} */
.info-window {
  color: black;
}

.info-window a {
  color: blue;
}

.pac-target-input {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 20rem;
  padding: 0.5rem;
  z-index: 1;
}

.lat-lng {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}


.graph-tooltip {
  position: absolute;
  min-width: 30px;
  max-width: 240px;
  border-radius: 4px;
  height: auto;
  background: rgba(250,250,250, 0.9);
  border: 1px solid #DDD;
  padding: 4px 8px;
  font-size: .85rem;
  text-align: left;
  z-index: 1000;
}